// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    >a {
        color: var(--#{$variable-prefix}gray-700);
    }

    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
            font-size: 15px;
            line-height: 20px;
        }
    }
}