.overlay-disable:disabled {
  background-color: #eff2f7 !important;
  color: black !important;
  border: 1px solid #ced4da !important;
}

.bg-white {
  background-color: #ffffff !important;
  cursor: "pointer";
}

.overlay-enabled {
  background-color: white !important;
  /* color: black !important; */
  /* border: 1px solid #ced4da !important; */
}

/* react select disabled class*/
.css-16xfy0z-control {
  background-color: #eff2f7 !important;
  color: black !important;
  border: 1px solid #ced4da !important;
}

.gridjs-th {
  background-color: #f3f3f9 !important;
}

.css-1s2u09g-control {
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
}

.custom_pagination ul {
  justify-content: end !important;
  margin-bottom: 0;
}

.area_expandable {
  position: relative;
}

.area_expandable input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  /* background-color: red; */
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.lbl_mandatory {
  color: red;
}

.icon_calendar {
  font-size: 23px;
  position: absolute;
  top: -7px;
}

.icon_time {
  font-size: 18px;
  position: absolute;
  top: -7px;
}

.card-header {
  align-items: center !important;
}

.booking_status {
  font-size: 11px !important;
}

.refresh-btn {
  display: flex !important;
  align-items: center !important;
}

.download-sample-excel {
  font-weight: 100;
  color: #405189;
  cursor: pointer;
}

.bg-pending {
  background-color: gray !important;
}

.bg-in-process {
  background-color: gray !important;
}

.bg-processed {
  background-color: orange !important;
}

.bg-confirmed {
  background-color: green !important;
}

.bg-cancelled {
  background-color: #f06548 !important;
}

.bg-completed {
  background-color: rgb(22, 143, 191) !important;
}

.bg-expired {
  background-color: gray;
}

.bg-redeemed {
  background-color: gray;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

#testtt::after {
  background: "red" !important;
  position: absolute;
  left: 1.25rem;
  top: 29px;
}

.setting-icon-benefit-group-item {
  z-index: 99;
  background-color: "#7e8b981a" !important;
  top: 17px;
  right: 1%;
}

.modal_header_color {
  background: #e6e6e6;
}

.corner-badge {
  position: absolute;
  top: 1px;
  right: 10px;
}

.color-black {
  color: black !important;
}

.error-accordion-item {
  border-color: red;
}

.error-accordion-heading {
  background: #ff484870 !important;
}

.gridjs-wrapper {
  border-radius: 2px !important;
}

.preview-item {
  min-height: 130px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.preview-item .action-btn {
  position: absolute;
  top: 5px;
  right: 10px;
}

.card_custom {
  margin-bottom: 1.5rem !important;
  -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  width: 98%;
  margin: 0px auto;
  background: #f4f4f4 !important;
  -webkit-box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  border: 5px solid #fff !important;
  /* padding: 6px; */
}

.card-inner {
  margin-bottom: 1.5rem !important;
  -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  width: 100%;
  margin: 0px auto;
  background: #fafafa !important;
  -webkit-box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  border: 5px solid #fff !important;
}

.accordion-body-custom {
  width: 100%;
  margin: 0px auto;
  background: #f5f6f9 !important;
  -webkit-box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  -moz-box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 1);
  /* border:5px solid #fff !important; */
}

/* .gridjs-tr {
  background: #ededed;
} */

.custom-striped-table .gridjs-tr:nth-child(odd) {
  color: black;
}

.custom-striped-table .gridjs-tr:nth-child(even) {
  background: #ededed;
}

.break-word {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-touch-icon.leaflet-zoom-animated.leaflet-interactive.leaflet-marker-draggable {
  margin-left: 0;
  margin-top: 0;
  width: 11px !important;
  height: 11px !important;
  transform: translate3d(639.005px, 204.001px, 0px);
  z-index: 206;
  border-radius: 50%;
  outline-style: none;
  color: black;
}

.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-touch-icon.leaflet-zoom-animated.leaflet-interactive {
  margin-left: 0;
  margin-top: 0;
  width: 11px !important;
  height: 11px !important;
  transform: translate3d(639.005px, 204.001px, 0px);
  z-index: 206;
  border-radius: 50%;
  outline-style: none;
  color: black;
}

@media only screen and (max-width: 767px) {
  .navgation-for-address {
    max-width: 100px;
  }
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mentions-container div div {
  min-height: 50px;
}

.mentions-container-body div {
  height: 100%;
}

#mentionsInputForBody {
  border: none;
  overflow: auto !important;
}

#mentionsInputForSubject {
  padding: 10px;
  border: 1px solid #0000004a;
  border-radius: 3px;
  background: white !important;
}

.dropzone {
  min-height: auto !important;
  cursor: pointer !important;
}

.custom-selector {
  span {
    display: none !important;
  }
}

.custom-btn-group label {
  margin-bottom: unset !important;
}

.date-picker-icon {
  /* position: absolute;
  right: 11px;
  bottom: 2px; */
  cursor: pointer;
}

.date-picker-icon2 {
  position: absolute;
  right: 11px;
  bottom: 2px;
  cursor: pointer;
}

.date-icons {
  display: flex;
  justify-content: right;
  position: absolute;
  right: 11px;
  bottom: 3px;
  align-content: center;
  gap: 7px;
}

.fsc-1 {
  font-size: 13.76px;
  font-weight: 400;
  line-height: 22.93px;
}

.hc-1 {
  font-size: 13px;
  font-weight: 500;
  line-height: 27.52px;
}

.ddd > div {
  display: inline-block;
  margin-left: 16px;
}

.max-w181 {
  max-width: 181px;
}

.max-w295 {
  max-width: 295px;
}

.max-w243 {
  max-width: 243px;
}

.select-icon-start {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.menu-start-icon-container {
  background: white;
  width: 65px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 22px 0px #00000012;
}

.qr-container {
  position: relative;
  margin: auto;
}

.otp-container > div {
  gap: 14px !important;
}

.data-table-container {
  margin: 20px;
}

.data-table-container h3 {
  margin-top: 20px;
}

.data-table-container pre {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
  max-height: 200px;
  overflow: auto;
}

.update-button {
  background-color: #4caf50;
  /* Green */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.update-button:hover {
  background-color: #45a049;
}

/* Custom table styles */
.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th {
  background-color: #3f51b5;
  /* Header background color */
  color: white;
  /* Header text color */
  border-bottom: 2px solid #ddd;
  /* Header bottom border */
}

.data-table td,
.data-table th {
  border: 1px solid #ddd;
  /* Cell borders */
  padding: 8px;
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2;
  /* Alternate row color */
}

.data-table tr:hover {
  background-color: #ddd;
  /* Hover row color */
}

.data-table td {
  text-align: left;
  /* Align text to the left */
}

.qr-img-container canvas {
  width: 200px !important;
  height: 200px !important;
}

.box-2 {
  width: 100%;
  max-width: 658px;
  min-height: 277px;
  background: #ffffffde;
  z-index: 100;
  position: relative;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 2px 0px 7px -3px #f0f0f0;
  margin: auto;
}

.Toastify__toast-container {
  width: 360px !important;
}

.hcgwbo {
  overflow: hidden;
  white-space: pre-line !important;
  text-overflow: ellipsis;
}

.custom-btn-group {
  z-index: 0 !important;
}

.cust-flatpickr {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding-right: 55px !important;
}

.custom-table-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  background-color: #fff;
}

.rdt_Pagination {
  position: sticky;
  bottom: 0;
  background: #f9fafb;
  border-top: 1px solid #ddd;
}

#main-voucher-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

#file-name-container {
  margin-top: 25px;
  font-size: 14px;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fafafa;
  color: #6f6f7d;
  font-family: Arial, sans-serif;
  width: 100%;
}

.dropzone {
  border: 2px dashed #c4c4c4 !important;
}

.sla-container {
  overflow-x: auto; /* Enables horizontal scrolling */
  padding-bottom: 10px; /* Prevents scrollbar overlap */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE & Edge */
}

.sla-title {
  margin: 0 !important;
  font-size: 13px;
  font-weight: 100;
}

/* Hides scrollbar in Chrome, Safari, and newer browsers */
.sla-container::-webkit-scrollbar {
  display: none;
}

.sla-scroll {
  display: flex;
  gap: 30px; /* Space between cards */
  min-width: 100%;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.sla-scroll > * {
  flex: 0 0 calc(93% / 3 - 10px);  
  /* /* max-width: calc(100% / 3 - 10px); */
  /* max-width: 400px; */
}

.sla-card{
  max-width: 400px;
  min-width: 200px;
}

.status-scroll {
  display: flex;
  gap: 15px; /* Space between cards */
  min-width: fit-content;
}

.status-scroll > * {
  flex: 0 0 calc(90% / 3); /* Ensure 3 cards fit within container */
  /* max-width: calc(96% / 3 - 10px); */
}

.action-scroll {
  display: flex;
  gap: 15px; /* Space between cards */
  min-width: fit-content;
}

.action-scroll > * {
  flex: 0 0 calc(90% / 3); /* Ensure 3 cards fit within container */
  /* max-width: calc(96% / 3 - 10px); */
}

.status-vendor-scroll {
  display: flex;
  gap: 15px; /* Space between cards */
  min-width: fit-content;
}


.status-vendor-scroll > * {
  flex: 0 0 calc(80% / 3); /* Ensure 3 cards fit within container */
  /* max-width: calc(96% / 3 - 10px); */
}

.filter-select{
  z-index: 99
}

.filter-conrainer::-webkit-scrollbar {
  display: none;
}

.custom-dropdown {
  min-width: 281px !important; /* Wider dropdown */
  padding: 12px !important; /* More spacing */
  transform: translate(-284px, 15px) !important; /* Adjust position */
}


@import "styleV2.css";
