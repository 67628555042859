.card-4 {
  border-radius: 29px !important;
  padding: 50px 0px;
}

.user-status {
  border: 1px solid var(--Input-Border, rgba(206, 212, 218, 1));
  height: 38px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.user-status-pointer {
  height: 8px;
  width: 8px;
  border-radius: 100%;
}

.user-status-pointer-success {
  background: rgba(10, 179, 156, 1);
}

.user-status-pointer-inactive {
  background: var(--Secondary-Text-Color, rgba(153, 153, 153, 1));
}

.user-status-pointer-out-break {
  background: rgba(230, 46, 63, 1);
}

.header-profile-user-2 {
  height: 40px;
  width: 40px;
}

.user-name-text-2 {
  font-size: 14px;
}

.card-3 {
  width: 232px;
  height: 213px;
  border: 1px solid var(--Input-Border, rgba(206, 212, 218, 1)) !important;
  background: var(--White, rgba(255, 255, 255, 1));
  border-radius: 24px !important;
  padding: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  overflow: hidden;
}

.logo-v2 {
  height: 38px;
}

.bg-3 {
  background: #f4f4f4;
  height: 100%;
  min-height: 100vh;
}

.footer-v2 {
  background: #eeeff5;
  padding: 25px 0px;
}

.card-5 {
  width: 338px;
  background: var(--White, rgba(255, 255, 255, 1));
  border-radius: 24px !important;
  padding: 20px;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}

.card-6 {
  width: 285px;
  background: var(--White, rgba(255, 255, 255, 1));
  border-radius: 24px !important;
  padding: 20px;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  margin-bottom: 7px !important;
  box-shadow: 1px 1px 10px -5px #808080ab !important;
}

.chart-1 {
  max-width: 90%;
  margin: auto;
}

.text-color-light-grey {
  color: rgba(153, 153, 153, 1);
}

.text-black {
  color: var(--Black, rgba(51, 51, 51, 1));
}

.text-black-1 {
  color: rgba(142, 142, 142, 1) !important;
}

.scrollable-1 {
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  padding: 1px;
}

.scrollable-1::-webkit-scrollbar {
  display: none;
}

.flex-2 {
  display: flex;
  align-items: center;
  gap: 6px;
}

.text-format-1 {
  font-size: 21.31px;
  font-weight: 500;
}

.text-format-2 {
  font-size: 14px;
  font-weight: 500;
}

.text-format-3 {
  font-weight: 600;
  font-size: 30px;
}

.flex-4 {
  display: flex;
  align-items: center;
  gap: 4px;
}

.text-format-7 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  letter-spacing: -0.02em;
}

.text-format-8 {
  color: rgba(10, 179, 156, 1);
  font-size: 14px;
}

@media screen and (max-width: 320px) {
  .logo-v2 {
    height: 25px;
  }
}

@media screen and (min-width: 600px) {
  .mt-sm-100 {
    margin-top: 100px !important;
  }
}

.status-badge-container {
  display: flex;
  gap: 5px;
  margin: 5px 0 25px 0;
}

.status-badge {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #333333;
}

.status-badge.active {
  background-color: #405189;
  border: 1px solid #405189;
}

.status-badge-text {
  color: #333333;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  margin-bottom: 0;
}

.status-badge.active .status-badge-text {
  color: #ffffff;
}

.horizontal-scrollor {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
}

.horizontal-scrollor::-webkit-scrollbar {
  display: none;
}

.booking-data-card-container {
  display: flex;
  gap: 17px;
  margin: 5px 0 0 5px;
}

.booking-id-details p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: 5%;
  margin-bottom: 0;
}

.booking-id-details p span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  letter-spacing: 5%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  color: #1e88e5;
  cursor: pointer;
}

.booking-tat-details {
  /* background: #fce2e2; */
  padding: 3px 14px 3px 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.booking-tat-details p {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0%;
  color: #000000;
  margin-bottom: 0;
}

.booking-details-heading {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #999999;
  text-wrap: wrap;
}

.booking-details-data {
  font-weight: 400;
  font-size: 13px;
  line-height: 18.66px;
  color: #000000;
  text-wrap: wrap;
}

.view-img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.custom-tabs-container {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px 0 0;
}

.custom-tab {
  cursor: pointer;
  padding: 10px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3f3f3f;
}

.custom-tab.active {
  border-bottom: 3px solid #405189;
}

.remaining-clock-time {
  width: 30px;
  height: 30px;
}

.tabular-remaining-clock {
  width: 20px;
  height: 23px;
}

.confirm-heading {
  margin-bottom: 0;
  color: #181d27;
  font-size: 18px;
  margin-bottom: 5px;
}

.confirm-sub-heading {
  margin-bottom: 0;
  color: #535862;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.running-car-container {
  text-align: center;
  margin: 20px 0;
}

.running-car {
  height: 100px;
}

.confirm-label {
  color: #535862;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
}

.confirm-service-date {
  color: #181d27;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
}

.confirm-body {
  color: #181d27;
  font-size: 16px;
  font-weight: 500;
}

.confirm-horizontal-line {
  border-bottom: "1px solid #E9EAEB";
}

.vendor-name {
  color: #181059;
  font-size: 20px;
  font-weight: 600;
}

.btn-replacer-spinner {
  width: 17px !important;
  height: 17px !important;
  font-size: 13px !important;
}

.vendor-main-div {
  display: flex;
  /* justify-content: space-between; */
  gap: 15px;
}

.vendor-cars {
  border-radius: 24px;
  width: 230px;
  padding: 25px 20px 30px;
  border: 1px solid #ccc;
  position: relative;
}

.active-vendor {
  border: 2px solid #0ab39c;
}

.selection-cta {
  background-color: #ccc;
  border-color: #ccc;
  width: 100%;
}

.selection-cta.selected {
  background-color: #181059;
  border-color: #181059;
}

.vendor-core-details {
  margin: 15px 0 20px;
}

.vendor-core-details .core-detail {
  display: flex;
  gap: 5px;
}

.vendor-core-details .core-detail p {
  margin-bottom: 0;
}

.vendor-core-details .core-detail img {
  width: 18px;
  height: 18px;
}

.vendor-price {
  color: #181059;
  font-weight: 600;
}

.cta-select-vendor {
  width: 100%;
}

.cta-select-vendor.active {
  background-color: #e9ebec !important;
  border: none !important;
  color: #181059 !important;
}

.vendor-selection-body {
  padding: 20px 5% 50px !important;
}

.vendor-booking-id {
  font-size: 16px;
}

.selected-vendor-container {
  position: absolute;
  top: 8%;
  right: 3%;
}

.selected-vendor-container .selected-vendor {
  width: 18px;
  height: 18px;
}

.tag-container {
  position: absolute;
  top: -5%;
  right: 11%;
  background: #405189;
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
}

.tag-label {
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 500;
}

.customized-btn {
  font-size: 12px !important;
  line-height: 0px !important;
  height: 35px !important;
}

.customized-btn.danger {
  border: 1px solid #ee6767 !important;
  background-color: white !important;
  color: #ee6767 !important;
  font-weight: 600 !important;
  font-size: 11px !important;
}

.customized-btn.danger:hover {
  border: 1px solid #ee6767 !important;
  background-color: white !important;
  color: #ee6767 !important;
  font-weight: 600 !important;
  font-size: 11px !important;
}

.custom-card-title {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.custom-section-heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.custom-section-label {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  /* margin-bottom: 3px; */
  margin-bottom: 0.4rem !important;
}

.custom-section-text {
  font-weight: 500;
  font-size: 14px;
  color: #121639;
  margin-bottom: 0;
}

.cancel-details-icon {
  cursor: pointer;
}

.booking-container {
  display: flex;
  gap: 20px;
}

.detail-item {
  margin-bottom: 10px;
}

.label {
  display: block;
  font-size: 14px;
  color: #888;
}

.value {
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.value.highlight {
  color: #f4a742;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.1rem !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.1rem !important;
}

.modification-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 10px;
}

/* .modification-container {
  border-bottom: 1px solid #DCDCDC;
} */
.modification-container:not(:last-child) {
  border-bottom: 1px solid #DCDCDC;
}

.modification-details-section {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.modification-status-tag {
  color: #405189;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.modification-date {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #343434;
  margin: 2px 0 8px;
}

.modification-action-by {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 2px;
}

.modification-remarks-section {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
}

.modification-remarks {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

.booking-truncate-details {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.booking-card {
  min-width: 400px !important;
  max-width: 400px !important;
  border: 1px solid #e9ebec;
  box-shadow: 1px 1px 10px -5px #808080ab !important;
}

.booking-card.active {
  border: 1px solid #abb9e8;
}

.booking-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modified-item {
  display: flex;
  flex-direction: column;
}

.booking-truncate-details {
  background-color: #f8f8f8;
  box-shadow: 0px 1px 16px 0px #202b5314;
  border-radius: 10px;

  gap: 20px;
  border-radius: 10px;
  padding-top: 18px;
  padding-right: 20px;
  padding-bottom: 18px;
  padding-left: 20px;
}

.modified-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: #121639;
  opacity: 50%;
}

.modified-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #121639;
}

.booking-prev-details-section,
.booking-prev-next-section {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.modification-section-heading, .booking-prev-next-section{
font-weight: 600;
font-size: 18px;
line-height: 27px;
color:#121639;
margin-bottom: 12px;
}

.make-ellipsis-10ch {
  display: inline-flex;
  max-width: 7ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}